import {configure, makeObservable, observable, reaction, computed} from 'mobx';
import React, {createContext} from 'react';
import ReactDOM from 'react-dom';

import autobind from 'common/decorators/autobind.js';
import Store from 'core/stores/Store.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import http from 'http.js';
import Button from 'core/components/Button.js';
import {REFERRALS, SITE} from 'core/stores/ParamStore.js';
import {FEATURE_INTEGRATED_PROFILE} from 'core/constants.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed', safeDescriptors: false});

export const PROFILE_TABS = {
    fees: 'profile-fees',
    writeReferral: 'profile-write-referral',
};

const menuSelector = '.menu > section:first-child nav';

export default class ProfileStore extends Store {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;
        makeObservable(this, {
            activeTab: observable,
            activeProfilePageTab: observable,
            client: observable,
            practices: observable,
            specialties: observable,
            avatar: observable,
            bio: observable,
            initials: observable,
            isEnhanced: observable,
            genderName: observable,
            languages: observable,
            profileId: observable,
            profileName: observable,
            qualifications: observable,
            specialInterests: observable,
            specialties: observable,
            selectedPractice: observable,
            gapSchemeResults: observable,
            gapSchemeResultsCount: observable,
            localityId: observable,
            specialtyId: observable,
            globalPromotedGapSchemes: observable,
            promotedGapSchemes: observable,
            isSpecialist: observable,
            captchaToken: observable,
            isIntegratedFeature: observable,
            integratedProfileFeatureMessageDisabled: observable,
            isRollout: observable,
            pageLoaded: observable,
            hospitals: observable,
            totalContributions: observable,
            ratings: observable,
            relatedInfo: observable,
            isRetired: observable,
            sendingSMS: observable,
            shouldRenderReturnToReferralsButton: computed,
            show: computed,
            tabs: computed,
            shouldShowLocationsTab: computed,
        });

        reaction(
            () => this.rootStore.healthFundStore?.healthFund?.id,
            () => {
                this.getGapSchemeResults();
            },
        );

        reaction(
            () => this.isIntegratedFeature,
            () => this.handleIntegratedProfileAppStorage(),
        );

        reaction(
            () => this.isMobileOrTablet || this.isMobile,
            () => this.setActiveTab(),
        );

        reaction(
            () => this.tabs,
            () => this.setActiveTab(),
        );
    }

    activeTab = PROFILE_TABS.writeReferral;
    activeProfilePageTab = ''; // which profile page tab to be displayed

    practices = [];
    specialties = [];
    specialInterests = '';
    qualifications = '';

    totalContributions = 0;
    ratings = null;
    relatedInfo = null;
    isRetired = false;

    isEnhanced = false;
    avatar = '';
    bio = '';
    initials = '';

    profileName = '';
    selectedPractice = 0;
    client = '';

    languages = [];
    genderName = '';

    hospitals = [];

    gapSchemeResults = [];
    gapSchemeResultsCount = 0;

    localityId = null;
    specialtyId = null;
    isSpecialist = false;
    profileId = 0;

    globalPromotedGapSchemes = [];
    promotedGapSchemes = [];

    captchaToken = null;
    sendingSMS = false;

    isIntegratedFeature = false;
    integratedProfileFeatureMessageDisabled = true;
    pageLoaded = false;
    isRollout = false;

    @autobind
    handleIntegratedProfileAppStorage() {
        const event = document.createEvent('CustomEvent');
        event.initCustomEvent(FEATURE_INTEGRATED_PROFILE, true, true, {
            isIntegratedFeature: this.isIntegratedFeature,
        });
        setTimeout(() => {
            // prevent event being fired before DOM is ready
            document.dispatchEvent(event);
        }, 0);
    }

    get shouldRenderReturnToReferralsButton() {
        return this.activeTab === PROFILE_TABS.fees && !!this.client;
    }

    get show() {
        return this.isReferrals ? REFERRALS : SITE;
    }

    getNextAvailableDate(practices) {
        // returns soonest date that is greater than the current date
        // with date of verification
        return practices.reduce((prev, practice) => {
            const nextDate = new Date(practice.nextAvailableDate);

            if (!nextDate) {
                return prev;
            }

            const currentDate = Date.now();
            const verificationDate = new Date(
                practice.nextAvailableVerificationDate,
            );
            const prevDate = prev?.nextAvailable;
            if (
                (!prevDate && nextDate > currentDate) ||
                (nextDate < prevDate && nextDate > currentDate)
            ) {
                return {
                    nextAvailable: nextDate,
                    verified: verificationDate,
                };
            }

            return prev;
        }, null);
    }

    getNextAvailableString(date) {
        if (date) {
            const {nextAvailable, verified} = date;

            const verifiedDate = verified.getTime();
            const nextAvailableDate = nextAvailable.getTime();
            const difference = nextAvailableDate - verifiedDate;
            const differenceInDays = Math.abs(
                Math.round(difference / (1000 * 3600 * 24)),
            );
            const months = Math.floor(differenceInDays / 31 + 1);
            return `Next availability within ${months} month${
                months > 1 ? 's' : ''
            }`;
        }

        return '';
    }

    getNextAvailableVerificationString(date) {
        if (date) {
            const month = date.toLocaleString('en-AU', {month: 'long'});
            const year = date.getFullYear();
            return `Indicative only - last verified ${month} ${year}`;
        }
        return '';
    }

    get tabs() {
        const DEFAULT_TABS = {
            'overview': 'Overview',
            'fees': 'Fees',
            'qa': 'QA',
            'ratings': 'Ratings',
            'related-info': 'RelatedInfo',
        };
        const {isRetired, ratings, relatedInfo, totalContributions} = this;
        const {hasSkin} = this.rootStore.paramStore;
        const {healthFunds} = this.rootStore.healthFundStore.healthFunds;
        const healthFundLength = healthFunds?.length;

        const hiddenTabs = [];
        if (isRetired) {
            hiddenTabs.push('overview');
        }
        if (healthFundLength === 0) {
            hiddenTabs.push('fees');
        }
        if (!ratings) {
            hiddenTabs.push('ratings');
        }
        if (!totalContributions || hasSkin) {
            hiddenTabs.push('qa');
        }
        if (!relatedInfo || hasSkin) {
            hiddenTabs.push('related-info');
        }
        const displayedTabs = Object.keys(DEFAULT_TABS).filter(
            (tabKey) => !hiddenTabs.includes(tabKey),
        );
        if (this.shouldShowLocationsTab) {
            const firstTab = displayedTabs.shift();
            displayedTabs.unshift(firstTab, 'locations');
        }
        return displayedTabs;
    }

    fetchDefaultTab() {
        if(this.rootStore.growthBookStore?.experimentInfo?.redesignDirectoryPlUp){
               return null
        }else{
            let tab;
            if (this.shouldShowLocationsTab && this.isMobileOrTablet) {
                tab = 'locations';
            } else if (this.isRetired && this.totalContributions) {
                tab = 'qa';
            } else {
                const firstTab = this.tabs[0];
                if (firstTab) {
                    tab = firstTab;
                }
            }
            this.replaceHashWith(`#${tab}`);
            this.updateStore({activeProfilePageTab: tab});
        }

    }

    @autobind
    async setActiveTab() {
        if (this.rootStore.growthBookStore?.experimentInfo?.redesignDirectoryPlUp) {
            return null;
        } else {
            let activeTab = null;
            const tab = window.location.hash.split('#')[1];
            if (window.location.hash && this.tabs.includes(tab)) {
                activeTab = tab;
            }
            if (tab && activeTab) {
                this.updateStore({activeProfilePageTab: activeTab});
                this.replaceHashWith(`#${tab}`);
            } else {
                this.fetchDefaultTab();
            }
        }
    }

    replaceHashWith(value) {
        if (value) {
            history.replaceState('', '', value);
        } else {
            history.replaceState(
                '',
                document.title,
                location.pathname + location.search,
            );
        }
    }

    get shouldShowLocationsTab() {
        const {hospitals, isRetired, practices} = this;
        const {client} = this.rootStore.paramStore;
        return !client && (practices.length || hospitals.length) && !isRetired;
    }

    @autobind
    returnToReferrals() {
        const menu = document.querySelector(menuSelector);
        menu.classList.remove('return-to-referrals');
        this.updateStore({activeTab: PROFILE_TABS.writeReferral});
    }

    @autobind
    renderReturnToReferralsButton() {
        const menu = document.querySelector(menuSelector);
        menu.classList.add('return-to-referrals');
        return ReactDOM.createPortal(
            <Button
                action={this.returnToReferrals}
                text="Back to referrals"
            />,
            menu,
        );
    }

    @autobind
    async getGapSchemeResults() {
        const selectedGapScheme = this.getSelectedGapScheme();
        const locality = this.practices[0]?.localityId;
        const specialty = this.specialties[0]?.id;
        if (!this.isEnhanced && locality && specialty && selectedGapScheme) {
            const url = '/api/professionals/v1/search/location/';
            const searchParams = {
                locality,
                specialty,
                gap_scheme: selectedGapScheme.gap_scheme_id, // eslint-disable-line camelcase
                max_distance: 150, // eslint-disable-line camelcase
            };
            let data = [];
            try {
                const response = await http.get({
                    url,
                    data: searchParams,
                });
                data = snakeToCamelObjectKeys(response);
            } catch (error) {
                throw new Error(`Search Results API returned ${error}`);
            } finally {
                this.updateStore({
                    localityId: locality,
                    specialtyId: specialty,
                    gapSchemeResults: data.results || [],
                    gapSchemeResultsCount: data.count || 0,
                });
            }
        } else {
            this.updateStore({
                localityId: undefined,
                specialtyId: undefined,
                gapSchemeResults: [],
            });
        }
    }

    @autobind
    getSelectedGapScheme() {
        const {healthFund} = this.rootStore.healthFundStore;
        if (healthFund?.id) {
            const globalFund = this.globalPromotedGapSchemes?.find(
                (scheme) => scheme.health_fund_id === healthFund.id, // eslint-disable-line camelcase
            );
            if (globalFund) {
                const gapScheme = this.promotedGapSchemes?.find(
                    (scheme) =>
                        scheme.healthFundId === globalFund.health_fund_id, // eslint-disable-line camelcase
                );
                if (globalFund.gap_scheme_id === gapScheme?.gapSchemeId) {
                    // eslint-disable-line camelcase
                    return null;
                } else {
                    this.updateStore({
                        globalFund,
                    });
                    return globalFund;
                }
            }
        }
    }

    @autobind
    async updateSelectedFund(fundId) {
        if (!fundId) {
            return;
        }
        await this.rootStore.healthFundStore.selectHealthFund(fundId);
        window.location.hash = '#fees';
    }
}
