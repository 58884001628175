import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'core/components/Avatar.js';
import Link from 'core/components/Link.js';
import {
    CALL_NOW_MODAL_BANNER_ADS,
    CALL_NOW_BUTTON_BANNER_ADS,
} from 'core/constants.js';

export default class PromotedProfileCard extends React.Component {
    static propTypes = {
        avatar: PropTypes.string,
        displayName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        initials: PropTypes.string.isRequired,
        profileUrl: PropTypes.string.isRequired,
        specialtyName: PropTypes.string.isRequired,
    };

    render() {
        const {avatar, displayName, initials, profileUrl, specialtyName} =
            this.props;

        return (
            <Link
                customClass="promoted-profile"
                href={profileUrl}
                gaSelector={CALL_NOW_MODAL_BANNER_ADS}
                gaObjectType={CALL_NOW_BUTTON_BANNER_ADS}
            >
                <Avatar
                    avatar={avatar}
                    displayName={displayName}
                    initials={initials}
                />
                <h2>{displayName}</h2>
                <p>{specialtyName}</p>
            </Link>
        );
    }
}
