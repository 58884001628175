import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import analytics from 'analytics.js';
import query from 'query.js';

import autobind from 'common/decorators/autobind.js';
import copyToClipboard from 'core/clipboard.js';
import Button from 'core/components/Button.js';
import {
    WRITE_REFERRAL_BTN,
    COPY_REFERRAL_BTN,
    COPY_REFERRAL_SELECT_PRAC_POSITION_BTN,
    BP,
    BP_FHIR,
    MD,
    ZM,
    SHOW_REFERRAL_BTN,
} from 'core/constants.js';
import {sessionStorageGetItem} from 'core/utils.js';

function trackOpenLetterTemplateEvent(eventData, isSecure) {
    // eslint-disable-next-line camelcase
    const {health_fund: healthFund, client} = query.parse();

    if (healthFund) {
        // eslint-disable-next-line camelcase
        eventData.health_fund = parseInt(healthFund);
    }
    if (client === BP_FHIR && isSecure) {
        eventData.isSecure = true;
    } else {
        eventData.isSecure = false;
    }
    const integrationUsageId = document.body.dataset.integrationUsageId || '';
    eventData['integration_usage_id'] = integrationUsageId;
    analytics.track('openLetterTemplate', eventData);
}

export function updateReferralDataForTelehealth(data, type) {
    const referralData = {...data};
    // remove address data if pure telehealth
    // zm data
    if (type == ZM) {
        referralData.PracticeAddress = 'Telehealth';
        return referralData;
    }
    // bp data
    delete referralData.AddressLine1;
    delete referralData.AddressLine2;
    delete referralData.Postcode;
    referralData.Suburb = 'Telehealth';

    //md data
    delete referralData.City;
    delete referralData.PCity;
    delete referralData.PPostcode;
    delete referralData.PStreetLine1;
    delete referralData.PStreetLine2;
    delete referralData.PStreetLine3;
    delete referralData.StreetLine1;
    delete referralData.StreetLine2;
    delete referralData.StreetLine3;

    return referralData;
}

export class GenericReferralButton extends React.Component {
    static propTypes = {
        sendSMSFunc: PropTypes.func,
    };

    @autobind
    handleClick(
        clientName,
        clientData,
        eventData,
        telehealthOnly,
        isSecure = false,
    ) {
        const {sendSMSFunc} = this.props;
        const letterData = telehealthOnly
            ? updateReferralDataForTelehealth(clientData)
            : clientData;

        trackOpenLetterTemplateEvent(eventData, isSecure);
        if (sendSMSFunc) {
            sendSMSFunc();
        }

        try {
            if (clientName === BP_FHIR) {
                window.external.searchFHIR(JSON.stringify(letterData));
            } else if (clientName === MD) {
                window.external.SelectAddressee(JSON.stringify(letterData));
            }
            window.external.writeReferral(JSON.stringify(letterData));
        } catch (e) {
            console.error('function not found');
        }
    }
}

export class BPWriteReferralButton extends GenericReferralButton {
    constructor() {
        super();
    }
    static propTypes = {
        bpData: PropTypes.shape({
            AddressLine1: PropTypes.string,
            AddressLine2: PropTypes.string,
            Category: PropTypes.string,
            Fax: PropTypes.string,
            FirstName: PropTypes.string,
            LastName: PropTypes.string,
            Phone: PropTypes.string,
            Postcode: PropTypes.string,
            PreferredName: PropTypes.string,
            Suburb: PropTypes.string,
        }).isRequired,
        customClass: PropTypes.string,
        eventData: PropTypes.object.isRequired,
        icon: PropTypes.string,
        impressionTrackingData: PropTypes.shape({
            action: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            label: PropTypes.string,
        }),
        telehealthOnly: PropTypes.bool,
    };

    @autobind
    handleClickButton() {
        const {bpData, eventData, telehealthOnly} = this.props;
        super.handleClick(BP, bpData, eventData, telehealthOnly, false);
    }

    render() {
        const {customClass, icon} = this.props;
        return (
            <Button
                action={this.handleClickButton}
                customClass={`cta ${customClass || ''}`.trim()}
                gaSelector={WRITE_REFERRAL_BTN}
                icon={icon}
                text="Write referral"
            />
        );
    }
}

export class BPSecureMessageButton extends GenericReferralButton {
    constructor() {
        super();
    }
    static propTypes = {
        customClass: PropTypes.string,
        eventData: PropTypes.object.isRequired,
        fhirData: PropTypes.shape({
            AddressLine1: PropTypes.string,
            AddressLine2: PropTypes.string,
            Category: PropTypes.string,
            Fax: PropTypes.string,
            FirstName: PropTypes.string,
            LastName: PropTypes.string,
            Phone: PropTypes.string,
            Postcode: PropTypes.string,
            PreferredName: PropTypes.string,
            Suburb: PropTypes.string,
        }).isRequired,
        icon: PropTypes.string,
        impressionTrackingData: PropTypes.shape({
            action: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            label: PropTypes.string,
        }),
        telehealthOnly: PropTypes.bool,
        text: PropTypes.string,
    };

    @autobind
    handleClickButton() {
        const {fhirData, eventData, telehealthOnly} = this.props;
        super.handleClick(BP_FHIR, fhirData, eventData, telehealthOnly, true);
    }

    render() {
        const {customClass, icon, text} = this.props;
        return (
            <Button
                action={this.handleClickButton}
                customClass={`cta ${customClass || ''}`.trim()}
                icon={icon}
                text={text || 'Refer via Secure Message'}
            />
        );
    }
}

export class HSReferralButton extends React.Component {
    static propTypes = {
        customClass: PropTypes.string,
        eventData: PropTypes.shape({
            eventName: PropTypes.string.isRequired,
            data: PropTypes.object.isRequired,
        }),
        getCopyData: PropTypes.func,
        icon: PropTypes.string,
        impressionTrackingData: PropTypes.shape({
            action: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            label: PropTypes.string,
        }),
        practice: PropTypes.shape({
            id: PropTypes.number.required,
            localityName: PropTypes.string.required,
            localityState: PropTypes.string.required,
            name: PropTypes.string,
            hsReferralsPhones: PropTypes.string,
            postCode: PropTypes.string.required,
            street1: PropTypes.string,
            street2: PropTypes.string,
        }),
    };

    constructor() {
        super();
        this.state = {
            classSuffix: '',
            ctaText: 'Copy referral details',
            disabled: false,
        };
    }

    getPracticeData() {
        const {
            localityName,
            localityState,
            name,
            telehealthOnly,
            hsReferralsPhones,
            postCode,
            street1,
            street2,
        } = this.props.practice;
        const {profileName} = this.props;
        const bits = [];
        if (name) {
            bits.push(name);
        }
        bits.push(profileName);
        if (!telehealthOnly) {
            if (street1) {
                bits.push(street1);
            }
            if (street2) {
                bits.push(street2);
            }
            bits.push(`${localityName} ${localityState} ${postCode}`);
        }
        if (hsReferralsPhones) {
            bits.push(hsReferralsPhones);
        }
        return bits.join('\r\n');
    }

    @autobind
    resetState() {
        this.setState({
            classSuffix: '',
            ctaText: 'Copy referral details',
            disabled: false,
        });
    }
    @autobind
    toggleState() {
        this.setState(
            {
                classSuffix: 'positive',
                ctaText: 'Copied',
                disabled: true,
            },
            () =>
                setTimeout(() => {
                    this.resetState();
                }, 1000),
        );
    }

    @autobind
    async copy() {
        const {getCopyData} = this.props;
        const {eventName, data} = this.props.eventData;
        const text = getCopyData ? getCopyData() : this.getPracticeData();
        copyToClipboard(text);
        this.toggleState();
        analytics.track(eventName, data);
    }

    render() {
        const {customClass, icon} = this.props;
        const {classSuffix, ctaText, disabled} = this.state;
        return (
            <Button
                action={() => this.copy()}
                customClass={`cta cta-copy ${classSuffix} ${
                    customClass || ''
                }`.trim()}
                disabled={disabled}
                gaSelector={COPY_REFERRAL_BTN}
                icon={icon}
                text={ctaText}
            />
        );
    }
}

export class MDWriteReferralButton extends GenericReferralButton {
    constructor() {
        super();
    }
    static propTypes = {
        customClass: PropTypes.string,
        eventData: PropTypes.object.isRequired,
        icon: PropTypes.string,
        mdData: PropTypes.shape({
            ABN: PropTypes.string,
            Category: PropTypes.string,
            City: PropTypes.string,
            Company: PropTypes.bool,
            eDirectoryId: PropTypes.number,
            Fax: PropTypes.string,
            FirstName: PropTypes.string,
            HomePhone: PropTypes.string,
            HPI: PropTypes.string,
            MobilePhone: PropTypes.string,
            OrganisationName: PropTypes.string,
            PCity: PropTypes.string,
            Postcode: PropTypes.string,
            PPostcode: PropTypes.string,
            ProviderNumber: PropTypes.string,
            PStreetLine1: PropTypes.string,
            PStreetLine2: PropTypes.string,
            PStreetLine3: PropTypes.string,
            Salutation: PropTypes.string,
            StreetLine1: PropTypes.string,
            StreetLine2: PropTypes.string,
            StreetLine3: PropTypes.string,
            Surname: PropTypes.string,
            Title: PropTypes.string,
            URL: PropTypes.string,
            WorkPhone: PropTypes.string,
        }).isRequired,
        telehealthOnly: PropTypes.bool,
    };

    @autobind
    handleClickButton() {
        const {mdData, eventData, telehealthOnly} = this.props;
        super.handleClick(MD, mdData, eventData, telehealthOnly, false);
    }

    render() {
        const {customClass, icon} = this.props;
        return (
            <Button
                action={this.handleClickButton}
                customClass={`cta ${customClass || ''}`.trim()}
                gaSelector={WRITE_REFERRAL_BTN}
                icon={icon}
                text="Write referral"
            />
        );
    }
}

export class ZMWriteReferralButton extends GenericReferralButton {
    constructor() {
        super();
    }
    static propTypes = {
        zmData: PropTypes.shape({
            PracticeAddress: PropTypes.string,
            Fax: PropTypes.string,
            FirstName: PropTypes.string,
            LastName: PropTypes.string,
            Phone: PropTypes.string,
            ReferralEmail: PropTypes.string,
        }).isRequired,
        eventData: PropTypes.object.isRequired,
        telehealthOnly: PropTypes.bool,
    };

    @autobind
    handleClickButton() {
        const {zmData, eventData, telehealthOnly} = this.props;
        super.handleClick(ZM, zmData, eventData, telehealthOnly, false);
    }

    render() {
        const {customClass, icon} = this.props;

        return (
            <Button
                action={this.handleClickButton}
                customClass={`cta ${customClass || ''}`.trim()}
                gaSelector={WRITE_REFERRAL_BTN}
                icon={icon}
                text="Write referral"
            />
        );
    }
}
export class MultipleReferralsButton extends React.Component {
    static propTypes = {
        clientData: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                bpData: PropTypes.object,
                mdData: PropTypes.object,
                zmData: PropTypes.object,
                referralEmail: PropTypes.string,
            }),
        ),
        customClass: PropTypes.string,
        icon: PropTypes.string,
        locations: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.object.isRequired,
        ).isRequired,
    };

    static defaultProps = {
        clientData: [],
        locations: [],
    };

    get bpData() {
        return this.props.clientData.find((data) => data.bpData);
    }

    get mdData() {
        return this.props.clientData.find((data) => data.mdData);
    }

    get zmData() {
        return this.props.clientData.find((data) => data.zmData);
    }

    get hasClientData() {
        return this.bpData || this.mdData || this.zmData;
    }

    @autobind
    handleClick() {
        const {
            client,
            clientData,
            displayName,
            isReferrals,
            locations,
            showModal,
        } = this.props;
        const customProps = {
            client,
            clientData,
            displayName,
            isReferrals,
            locations,
        };
        showModal('referralModal', customProps);
    }

    render() {
        const {customClass, icon, isReferrals, locations, client} = this.props;
        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';

        if (!locations.length) {
            return null;
        }

        let btnTxt;
        let gaSelectorStr;

        if (this.bpData || this.mdData || (this.zmData && showReferralBtn)) {
            btnTxt = 'Write referral';
            gaSelectorStr = WRITE_REFERRAL_BTN;
        } else if ((this.zmData && !showReferralBtn) || isReferrals) {
            btnTxt = 'Copy referral details';
            gaSelectorStr = COPY_REFERRAL_SELECT_PRAC_POSITION_BTN;
        } else {
            return null;
        }

        return (
            <Button
                action={this.handleClick}
                customClass={`cta ${customClass || ''}`.trim()}
                gaSelector={gaSelectorStr}
                icon={icon}
                text={btnTxt}
            />
        );
    }
}
